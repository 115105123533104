<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="flex succesuss">
    <div slot="no-body">
      <div class="justify-center items-center">
        <div class="d-theme-dark-bg" style="border-radius: 15px">

<p class="MsoNormal"><b><span  style="font-size:16.0pt;line-height:115%;color:#1F497D;mso-themecolor:text2">{{$t('Strategicalliances')}}<o:p></o:p></span></b></p>

<p class="MsoNormal"><b><span  style="font-size:16.0pt;line-height:115%;color:#1F497D;mso-themecolor:text2">&nbsp;</span></b></p>

<p class="MsoNormal"><b><span  style="font-size:14.0pt;line-height:115%;color:#00B050">{{$t('Beapart')}}<o:p></o:p></span></b></p>

<p class="MsoNormal"><b><span  style="font-size:14.0pt;line-height:115%">&nbsp;</span></b></p>

<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('Part1')}}<o:p></o:p></span></p>

<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">&nbsp;</span></p>

<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('Part2')}}<o:p></o:p></span></p>

<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">&nbsp;</span></p>

<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('Part3')}}<o:p></o:p></span></p>
 <br/>
<p class="vx-row"> <img src="../assets/images/GreenPoint.png" alt=""> <span class="mt-6">{{$t('Part4')}}</span></p>
<p class="vx-row"><img src="../assets/images/GreenPoint.png" alt=""> <span class="mt-6">{{$t('Part5')}}</span></p>
<p class="vx-row"><img src="../assets/images/GreenPoint.png" alt=""> <span class="mt-6">{{$t('Part6')}}</span></p>
<p class="vx-row"><img src="../assets/images/GreenPoint.png" alt=""> <span class="mt-6">{{$t('Part7')}}</span></p>
<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">&nbsp;</span></p>


<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">&nbsp;</span></p>

<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">&nbsp;</span></p>

<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('OurTarget')}}<o:p></o:p></span></p>

<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('ContactUs')}} </span><span ><a href="mailto:info@doclinia.com"><span style="font-size:12.0pt;line-height:115%;
color:#00B050">info@doclinia.com</span></a></span><span  style="font-size:12.0pt;line-height:115%;color:#1F497D;mso-themecolor:text2">
{{$t('LetUs')}}<o:p></o:p></span></p>

<span  style="font-size:11.0pt;line-height:115%;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;
mso-fareast-font-family:Arial;color:#1F497D;mso-themecolor:text2;mso-ansi-language:
EN;mso-fareast-language:EN-US;mso-bidi-language:AR-SA"><br clear="all" style="mso-special-character:line-break;page-break-before:always">
</span>


 </div>
    </div>
  </div>
  </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player'

export default {
  data() {
    return {
        playerOptions: {
          // videojs options
          muted: false,
          language: 'en',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{
            type: "video/mp4",
            src: require("../assets/DocliniaDemoVideo.mp4")
          }],
          poster: "/static/images/author.jpg",
        },
    };
  },
  props: {
    Message: {
      type: String,
      default: () => "",
    },
  },
  components: {
    videoPlayer
  },
  computed: {},
  created() {},
};
</script>

<style lang="scss">
.succesuss {
  width: 100%;
  margin: auto;
}
.logo {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  text-align: center;
}
.P-Text {
  color: #004477;
}
</style>


